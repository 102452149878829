.App {
    margin: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 100vh;

    background-image: url('./assets/background.jpeg');
    color: #211A1E;
}