.blue-circle {
  border-radius: 50%;

  margin: auto;

  display: flex;
  align-items: center;
  justify-content: center;
}

.breath-text .time-remaining {
  font-size: 1.5rem;
  display: flex;
  justify-content: center
}